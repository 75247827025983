import request from '@/api/request'

export function listGpsUser (data) {
  return request({
    url: 'gps/user/list',
    method: 'post',
    data: data
  })
}

export function listAllGpsUser () {
  return request({
    url: 'gps/user/listAll',
    method: 'get'
  })
}

export function createGpsUser (data) {
  return request({
    url: 'gps/user/create',
    method: 'post',
    data: data
  })
}

export function updateGpsUser (data) {
  return request({
    url: 'gps/user/update',
    method: 'post',
    data: data
  })
}

export function getGpsUser (userId) {
  return request({
    url: 'gps/user/getGpsUser/' + userId,
    method: 'get'
  })
}

export function deleteGpsUser (userId) {
  const data = {
    userId: userId
  }
  return request({
    url: 'gps/user/delete',
    method: 'post',
    data: data
  })
}

export function getGpsUserByUserName (userName) {
  const data = {
    userName: userName
  }
  return request({
    url: '/gps/user/getGpsUserByName',
    method: 'post',
    data: data
  })
}

export function resetPassword (userId) {
  const data = {
    userId: userId
  }
  return request({
    url: '/gps/user/resetPassword',
    method: 'post',
    data: data
  })
}
