const deviceType = new Map([
  [ 1, 'deviceType.vehicle' ],
  [ 2, 'deviceType.pigeon' ],
  [ 3, 'deviceType.studentCard' ],
  [ 4, 'deviceType.recorder' ]
])

const locationType = new Map([
  [ 0, 'locationType.invalid' ],
  [ 1, 'locationType.gps' ],
  [ 2, 'locationType.baseStation' ],
  [ 3, 'locationType.NBIOT' ]
])

const errorCode = new Map([
  [ -1, 'errorCode.requestError' ],
  [ 1000, 'errorCode.internalError' ],
  [ 2004, 'errorCode.tokenExpired' ],
  [ 3000, 'errorCode.accountNotExistOrPasswordError' ],
  [ 3001, 'errorCode.userNotExist' ],
  [ 3002, 'errorCode.deviceNotExist' ],
  [ 3006, 'errorCode.oldPasswordError' ],
  [ 3007, 'errorCode.userExpired' ],
  [ 3008, 'errorCode.deviceImeiExisted' ],
  [ 3009, 'errorCode.deviceNameExisted' ],
  [ 3010, 'errorCode.accountExisted' ],
  [ 3011, 'errorCode.passwordError' ],
  [ 3013, 'errorCode.deviceBound' ],
  [ 3015, 'errorCode.noAuthorizationToUnbind' ],
  [ 5006, 'errorCode.noAuthorizationToOperate' ],
  [ 21000, 'errorCode.verifyFailed' ],
  [ 21001, 'errorCode.authorizationCodeExpired' ]
])

export function getErrorDescIndex (code) {
  if (errorCode.has(code)) {
    return errorCode.get(code)
  } else {
    return errorCode.get(-1)
  }
}

export function getErrroDescStr (context, code) {
  if (deviceType.has(code)) {
    return context.$t(deviceType.get(code))
  } else {
    return context.$t(deviceType.get(2))
  }
}

export function getDeviceTypeStr (context, type) {
  if (deviceType.has(type)) {
    return context.$t(deviceType.get(type))
  } else {
    return context.$t(deviceType.get(2))
  }
}

export function getLocationTypeStr (context, type) {
  if (locationType.has(type)) {
    return context.$t(locationType.get(type))
  } else {
    return context.$t(locationType.get(0))
  }
}

export function getDeviceTypeIndex (type) {
  if (deviceType.has(type)) {
    return deviceType.get(type)
  } else {
    return deviceType.get(2)
  }
}

export function getLocationTypeIndex (type) {
  if (locationType.has(type)) {
    return locationType.get(type)
  } else {
    return locationType.get(0)
  }
}
