import request from '@/api/request'

export function listCode (data) {
  return request({
    url: 'code/list',
    method: 'post',
    data: data
  })
}

export function listCodeByClass (codeClass) {
  return request({
    url: 'code/listCodeClass/' + codeClass,
    method: 'get'
  })
}

export function listOptionalUserType () {
  return request({
    url: 'code/listOptionalUserType',
    method: 'get'
  })
}

export function getCodeByClassAndValue (codeClass, codeValue) {
  const data = {
    codeClass: codeClass,
    codeValue: codeValue
  }
  return request({
    url: 'code/getCodeByClassAndValue',
    method: 'post',
    data: data
  })
}

export function insertCode (data) {
  const code = {
    codeName: data.codeName,
    codeClass: data.codeClass,
    codeValue: data.codeValue,
    codeDesc: data.codeDesc
  }
  return request({
    url: 'code/insertCode',
    method: 'post',
    data: code
  })
}

export function updateCode (data) {
  const code = {
    codeName: data.codeName,
    codeClass: data.codeClass,
    codeValue: data.codeValue,
    codeDesc: data.codeDesc
  }
  return request({
    url: 'code/updateCode',
    method: 'post',
    data: code
  })
}

export function deleteCode (codeClass, codeValue) {
  const code = {
    codeClass: codeClass,
    codeValue: codeValue
  }
  return request({
    url: 'code/deleteCode',
    method: 'post',
    data: code
  })
}
